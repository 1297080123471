/*
@tailwind base;

@tailwind components;
*/

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity))
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

.rounded-4 {
  border-radius: .4rem
}

.rounded-6 {
  border-radius: .6rem
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-24 {
  border-radius: 2.4rem
}

.rounded-none {
  border-radius: 0
}

.rounded {
  border-radius: .4rem
}

.rounded-lg {
  border-radius: .8rem
}

.rounded-full {
  border-radius: 9999px
}

.border-solid {
  border-style: solid
}

.border-0 {
  border-width: 0px
}

.border-b-1 {
  border-bottom-width: 1px
}

.border-l-1 {
  border-left-width: 1px
}

.border-l {
  border-left-width: 1px
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-grow-0 {
  flex-grow: 0
}

.flex-grow {
  flex-grow: 1
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.font-300 {
  font-weight: 300
}

.font-500 {
  font-weight: 500
}

.font-600 {
  font-weight: 600
}

.font-light {
  font-weight: 300
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.h-0 {
  height: 0
}

.h-8 {
  height: 0.8rem
}

.h-16 {
  height: 1.6rem
}

.h-20 {
  height: 2rem
}

.h-40 {
  height: 4rem
}

.h-48 {
  height: 4.8rem
}

.h-52 {
  height: 5.2rem
}

.h-56 {
  height: 5.6rem
}

.h-64 {
  height: 6.4rem
}

.h-72 {
  height: 7.2rem
}

.h-96 {
  height: 9.6rem
}

.h-192 {
  height: 19.2rem
}

.h-auto {
  height: auto
}

.h-full {
  height: 100%
}

.text-10 {
  font-size: 1rem
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-15 {
  font-size: 1.5rem
}

.text-16 {
  font-size: 1.6rem
}

.text-18 {
  font-size: 1.8rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-32 {
  font-size: 3.2rem
}

.text-44 {
  font-size: 4.4rem
}

.text-48 {
  font-size: 4.8rem
}

.text-56 {
  font-size: 5.6rem
}

.leading-tight {
  line-height: 1.25
}

.m-8 {
  margin: 0.8rem
}

.m-32 {
  margin: 3.2rem
}

.mx-0 {
  margin-left: 0;
  margin-right: 0
}

.mx-2 {
  margin-left: 0.2rem;
  margin-right: 0.2rem
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.-mx-2 {
  margin-left: -0.2rem;
  margin-right: -0.2rem
}

.-mx-8 {
  margin-left: -0.8rem;
  margin-right: -0.8rem
}

.-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem
}

.mt-0 {
  margin-top: 0
}

.mt-4 {
  margin-top: 0.4rem
}

.mr-4 {
  margin-right: 0.4rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.mb-7 {
  margin-bottom: 0.7rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-8 {
  margin-right: 0.8rem
}

.mb-8 {
  margin-bottom: 0.8rem
}

.ml-10 {
  margin-left: 1.0rem
}

.mt-12 {
  margin-top: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mr-16 {
  margin-right: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.mr-24 {
  margin-right: 2.4rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.mt-48 {
  margin-top: 4.8rem
}

.-mb-10 {
  margin-bottom: -1rem
}

.-mt-24 {
  margin-top: -2.4rem
}

.-mb-28 {
  margin-bottom: -2.8rem
}

.-mt-52 {
  margin-top: -5.2rem
}

[dir='ltr'] .ltr\:ml-4,[dir='ltr'].ltr\:ml-4 {
  margin-left: 0.4rem
}

[dir='ltr'] .ltr\:ml-8,[dir='ltr'].ltr\:ml-8 {
  margin-left: 0.8rem
}

[dir='ltr'] .ltr\:mr-48,[dir='ltr'].ltr\:mr-48 {
  margin-right: 4.8rem
}

[dir='rtl'] .rtl\:mr-4,[dir='rtl'].rtl\:mr-4 {
  margin-right: 0.4rem
}

[dir='rtl'] .rtl\:mr-8,[dir='rtl'].rtl\:mr-8 {
  margin-right: 0.8rem
}

[dir='rtl'] .rtl\:ml-48,[dir='rtl'].rtl\:ml-48 {
  margin-left: 4.8rem
}

.max-h-0 {
  max-height: 0
}

.max-h-full {
  max-height: 100%
}

.max-w-64 {
  max-width: 6.4rem
}

.max-w-128 {
  max-width: 12.8rem
}

.max-w-384 {
  max-width: 38.4rem
}

.max-w-512 {
  max-width: 51.2rem
}

.max-w-2xl {
  max-width: 112rem
}

.max-w-full {
  max-width: 100%
}

.min-h-0 {
  min-height: 0
}

.min-h-40 {
  min-height: 4rem
}

.min-h-48 {
  min-height: 4.8rem
}

.min-h-72 {
  min-height: 7.2rem
}

.min-h-96 {
  min-height: 9.6rem
}

.min-w-0 {
  min-width: 0
}

.min-w-20 {
  min-width: 2rem
}

.min-w-40 {
  min-width: 4rem
}

.min-w-64 {
  min-width: 6.4rem
}

.opacity-50 {
  opacity: 0.5
}

.opacity-75 {
  opacity: 0.75
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-y-hidden {
  overflow-y: hidden
}

.p-0 {
  padding: 0
}

.p-1 {
  padding: 0.1rem
}

.p-4 {
  padding: 0.4rem
}

.p-8 {
  padding: 0.8rem
}

.p-10 {
  padding: 1.0rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-24 {
  padding: 2.4rem
}

.p-32 {
  padding: 3.2rem
}

.p-48 {
  padding: 4.8rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.py-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem
}

.px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem
}

.pb-4 {
  padding-bottom: 0.4rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pt-16 {
  padding-top: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pr-28 {
  padding-right: 2.8rem
}

.pb-32 {
  padding-bottom: 3.2rem
}

.pl-32 {
  padding-left: 3.2rem
}

.pb-40 {
  padding-bottom: 4rem
}

.pl-40 {
  padding-left: 4rem
}

.pt-52 {
  padding-top: 5.2rem
}

.pb-52 {
  padding-bottom: 5.2rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

.pb-96 {
  padding-bottom: 9.6rem
}

[dir='ltr'] .ltr\:pl-4,[dir='ltr'].ltr\:pl-4 {
  padding-left: 0.4rem
}

[dir='ltr'] .ltr\:pr-48,[dir='ltr'].ltr\:pr-48 {
  padding-right: 4.8rem
}

[dir='rtl'] .rtl\:pr-4,[dir='rtl'].rtl\:pr-4 {
  padding-right: 0.4rem
}

[dir='rtl'] .rtl\:pl-48,[dir='rtl'].rtl\:pl-48 {
  padding-left: 4.8rem
}

.pointer-events-none {
  pointer-events: none
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

[dir='ltr'] .ltr\:right-0,[dir='ltr'].ltr\:right-0 {
  right: 0
}

[dir='ltr'] .ltr\:left-0,[dir='ltr'].ltr\:left-0 {
  left: 0
}

[dir='rtl'] .rtl\:right-0,[dir='rtl'].rtl\:right-0 {
  right: 0
}

[dir='rtl'] .rtl\:left-0,[dir='rtl'].rtl\:left-0 {
  left: 0
}

* {
  --tw-shadow: 0 0 #0000
}

.shadow-0 {
  --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(33, 150, 243, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-justify {
  text-align: justify
}

.text-red {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity))
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.line-through {
  text-decoration: line-through
}

.hover\:underline:hover {
  text-decoration: underline
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.whitespace-nowrap {
  white-space: nowrap
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.w-16 {
  width: 1.6rem
}

.w-20 {
  width: 2rem
}

.w-24 {
  width: 2.4rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-64 {
  width: 6.4rem
}

.w-128 {
  width: 12.8rem
}

.w-192 {
  width: 19.2rem
}

.w-256 {
  width: 25.6rem
}

.w-320 {
  width: 32rem
}

.w-512 {
  width: 51.2rem
}

.w-xs {
  width: 32rem
}

.w-1\/2 {
  width: 50%
}

.w-1\/4 {
  width: 25%
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-999 {
  z-index: 999
}

.z-9999 {
  z-index: 9999
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-shadow {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@media (min-width: 600px) {

  .sm\:flex {
    display: flex
  }

  .sm\:table-cell {
    display: table-cell
  }

  .sm\:hidden {
    display: none
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:h-56 {
    height: 5.6rem
  }

  .sm\:h-72 {
    height: 7.2rem
  }

  .sm\:h-96 {
    height: 9.6rem
  }

  .sm\:h-136 {
    height: 13.6rem
  }

  .sm\:h-160 {
    height: 16rem
  }

  .sm\:text-40 {
    font-size: 4rem
  }

  .sm\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  .sm\:min-h-72 {
    min-height: 7.2rem
  }

  .sm\:min-h-96 {
    min-height: 9.6rem
  }

  .sm\:min-h-136 {
    min-height: 13.6rem
  }

  .sm\:min-h-160 {
    min-height: 16rem
  }

  .sm\:p-1 {
    padding: 0.1rem
  }

  .sm\:p-8 {
    padding: 0.8rem
  }

  .sm\:p-12 {
    padding: 1.2rem
  }

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:p-32 {
    padding: 3.2rem
  }

  .sm\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  .sm\:py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem
  }

  .sm\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .sm\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .sm\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .sm\:pt-0 {
    padding-top: 0
  }

  .sm\:pb-24 {
    padding-bottom: 2.4rem
  }

  .sm\:w-224 {
    width: 22.4rem
  }

  .sm\:w-320 {
    width: 32rem
  }

  .sm\:w-1\/2 {
    width: 50%
  }
}

@media (min-width: 960px) {

  .md\:flex {
    display: flex
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:h-48 {
    height: 4.8rem
  }

  .md\:h-64 {
    height: 6.4rem
  }

  .md\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  .md\:min-h-64 {
    min-height: 6.4rem
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .md\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  .md\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .md\:py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem
  }

  .md\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  .md\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  [dir='ltr'] .md\:ltr\:pr-32,[dir='ltr'].md\:ltr\:pr-32 {
    padding-right: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:pl-32,[dir='rtl'].md\:rtl\:pl-32 {
    padding-left: 3.2rem
  }

  .md\:w-320 {
    width: 32rem
  }
}

@media (min-width: 1280px) {

  .lg\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .lg\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .lg\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  [dir='ltr'] .lg\:ltr\:pl-0,[dir='ltr'].lg\:ltr\:pl-0 {
    padding-left: 0
  }

  [dir='ltr'] .lg\:ltr\:pl-16,[dir='ltr'].lg\:ltr\:pl-16 {
    padding-left: 1.6rem
  }

  [dir='rtl'] .lg\:rtl\:pr-0,[dir='rtl'].lg\:rtl\:pr-0 {
    padding-right: 0
  }

  [dir='rtl'] .lg\:rtl\:pr-16,[dir='rtl'].lg\:rtl\:pr-16 {
    padding-right: 1.6rem
  }
}
