@import-normalize;

@import 'tailwind-base.css';

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
	transition: none !important;
	animation: none !important;
}

html {
	font-size: 62.5%;
	font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
	background-color: #121212;
}

body {
	font-size: 14px;
	line-height: 1.4;
}

html,
body,
#root {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	margin: 0;
}

h1,
.h1 {
	font-size: 24px;
}

h2,
.h2 {
	font-size: 20px;
}

h3,
.h3 {
	font-size: 16px;
}

h4,
.h4 {
	font-size: 15px;
}

h5,
.h5 {
	font-size: 13px;
}

h6,
.h6 {
	font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
	z-index: 99;
}

a[role='button'] {
	text-decoration: none;
}

[role='tooltip'] {
	z-index: 9999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		background-color: rgba(0, 0, 0, 0);
	}

	::-webkit-scrollbar:hover {
		width: 8px;
		height: 8px;
		background-color: rgba(0, 0, 0, 0.06);
	}

	::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		border-radius: 20px;
	}

	::-webkit-scrollbar-thumb:active {
		border-radius: 20px;
	}
}

form label {
	z-index: 99;
}

@keyframes autofill {
	0%,
	100% {
		background: transparent;
	}
}

input:-webkit-autofill {
	animation-delay: 1s;
	animation-name: autofill !important;
	animation-fill-mode: both;
	-webkit-box-shadow: 0 0 0 100px transparent inset !important;
}

:focus {
	outline-color: transparent;
}

@import 'print.css';

@import 'tables.css';

@import 'prism.css';

@import 'tailwind.css';

[data-placeholder]:empty:before {
	content: attr(data-placeholder);
	color: #aaa;
	font-style: italic;
}
